/* SocialMediaApprovalComponent.css */


* {
  font-family: Cairo, sans-serif;

}

.social-media-approval {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Cairo, sans-serif;
  }


  .image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Style for each image item */
  .image-item {
    flex: calc(50% - 10px); /* Two images per row with gap */
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 20px; /* Add some spacing between rows */
  }
  
  /* Style for each action button */
  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  input {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 48%; /* Ensure buttons fit within the image item */
    padding: 5px 10px;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #007bff;
    color: white;
  }
  
  h1 {
    text-align: center;

  }

  
  